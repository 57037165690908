.footer a {
    text-decoration: none;
    margin:0 10px;
}
.footer a:link {
    color: rgba(255,255,255,0.7);
}

.links a {
    color: rgba(255,255,255,0.7);
}

.copyright a {
    color: rgba(255,255,255,0.7);
}
