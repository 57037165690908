.container {
    position: relative;
}

.main {
    /*max-width: 1660px;*/
    margin: 0 auto;
}

.part-search {
    /*background: linear-gradient(180deg, #f4f4f4 0%, #161616 100%);*/
    background-color: #262626;
    position: relative;

    border-radius: 10px;
    height: 346px;

    margin-bottom: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}


.part-search .search-title {
    font-size: 2em;
    line-height: 1.17647059em;
    margin-bottom: .88235294em;
    color: #fff;
    white-space: nowrap;
    font-family: pingfang;
    font-weight: normal;
}
.part-search .search-title-small {
    font-size: 1.5em;
    line-height: 1.17647059em;
    margin-bottom: .88235294em;
    color: #fff;
    white-space: nowrap;
    font-family: pingfang;
    font-weight: normal;
}

.part-search .search-main {
    text-align: center;
    width:100%;
}

.c-loop {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    padding: 10px;
}

.c-loop-xs {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
    padding: 10px;
}
.c-loop-sm {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 10px;
    padding: 10px;
}
.c-loop-md {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 10px;
    padding: 10px;
}
.c-loop-lg {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-gap: 10px;
    padding: 10px;
}

.p-item {
    /*margin:10px;*/
}

.p-item .item-tag {
    text-align: center;
    height: 20px;
    overflow: hidden;
    margin-top: 12px;
}

.item-tag .tag {
    display: inline-block;
    background-color: #f1f7fc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    line-height: 20px;
    white-space: nowrap;
    font-size: 12px;
    padding: 0 6px;
    color: #9ca7ae;
    /*margin-right: 5px;*/
    margin:0 3px;
}

.item-thumb img {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    overflow: hidden;
}

.item-title {
    margin-bottom: 6px;
    font-size: 16px;
    color: #3c3c3c;
}

.item-desc {
    font-size: 12px;
    color: #8f8f8f;
    line-height: 18px;
    height: 36px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    text-align: center;
    margin-bottom: 20px;
}
